<template>
  <div class="attaced-box">
    <div class="attaced-title">Attach Documents</div>
    <div class="upload-box" v-loading="loading">
      <el-upload
        multiple
        drag
        :http-request="uploadFile"
        action="imagess"
        list-type="picture-card"
        :show-file-list="false"
      >
        <div class="upload-row">
          <img src="../../../assets/icon/icon-upload.svg" alt="">
          <span class="upload-text">Drop files here or click to upload</span>
        </div>
      </el-upload>
    </div>
    <div class="file_list" v-for="(item, index) in data" :key="index">
      <div class="list_name">
        <img src="../../../assets/img/file.png" alt />
        {{ item.file_name }}
      </div>
      <div class="show_tant">
        <span>
          <a @click="view_flie(item.url)" target="view_window">VIEW</a>
        </span>
        <el-button
          type="text"
          @click="delFlie(item.object_uuid)"
          icon="el-icon-delete"
        ></el-button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  apiUploadFile,
  apiSecurityDepositUploadFile,
  apiRemoveFile,
} from "../../../API/api";
export default {
  props: ["data"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    // 上传成功
    uploadFile(file) {
      let form = new FormData();
      form.append("file", file.file);
      this.loading = true;
      apiUploadFile(form)
        .then((res) => {
          this.data.unshift(res)
          // this.getFiles(res.object_uuid);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 上传保证金附件
    getFiles(id) {
      apiSecurityDepositUploadFile(this.$route.params.id, { attach_file: id })
        .then((res) => {
          this.data = res.attach_files;
        })
        .catch((err) => {
          this.$message.error(err.response.data.detail);
        });
    },
    // 删除附件
    delFlie(id) {
      apiRemoveFile(this.$route.params.id, { attach_file: id })
        .then((res) => {
          this.data.forEach((item, index) => {
            if (item.object_uuid === id) {
              this.data.splice(index, 1);
            }
          });
          this.$message.success("Delete successful");
        })
        .catch((err) => {
          this.$message.error(
            "The file can only be deleted by the person who created it."
          );
        });
    },
    //  查看附件
    view_flie(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.attaced-box {
  margin-top: 40px;
  .attaced-title {
    margin-top: 10px;
    text-align: left;
    font-family: "Roboto-Bold", sans-serif;
    font-size: 18px;
    color: #36425d;
  }
  .upload-box {
    width: 100%;
    margin: 20px 0 20px 0;
    /deep/ .el-upload--picture-card {
      width: 100%;
      text-align: center;
      background-color: #f4f9fc;
      border: 1px Dashed #678993;
      height: 100px;
      line-height: 100px;
      .el-upload-dragger {
        height: 100%;
        width: 100%;
        border: none;
        background-color: #f4f9fc;
        border-radius: 0;
        box-shadow: none;
        .upload-row {
          display: flex;
          align-items: center;
          justify-content: center;
          .upload-text,
          i {
            color: #72949d;
          }
          .upload-text {
            margin-left: 7px;
            font-size: 14px;
          }
        }
      }
    }
  }
  .file_list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-top: 1px solid #e5e5e5;
    .list_name {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #678993;
      img {
        width: 22px;
        height: 23px;
        margin-right: 15px;
      }
    }
    .show_tant {
      span {
        color: #333;
        display: inline-block;
        margin-right: 40px;
        &:hover {
          cursor: pointer;
        }
      }
      .el-button {
        color: #36425d;
        font-size: 22px;
      }
    }
    &:first-child {
      border: none;
    }
  }
}
</style>