<template>
  <div class="note_box">
    <slot name="note-title"><div class="note-title"></div></slot>
    
    <div class="table">
      <el-table :data="noteData">
        <el-table-column label="DATE" width="150px">
          <template slot-scope="scope">{{ scope.row.date | dateFormat }}</template>
        </el-table-column>
        <el-table-column prop="content" label="NOTE">
          <template slot-scope="scope">
            <div class="note-text" v-if="scope.row.content.length <= 400">{{ scope.row.content }}
            </div>
            <div v-else-if="scope.row.content.length > 400">
              <div v-show="!scope.row.show">
                <p class="note-text">{{ scope.row.content | intercept }}...</p>
                <p class="fr" @click="openShow(scope.$index)">READ MORE</p>
              </div>
              <div v-show="scope.row.show">
                <p class="note-text">{{ scope.row.content | character }}</p>
                <p class="fr" @click="closeShow(scope.$index)">PACK UP</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="80px">
          <template slot-scope="scope">
            <el-button
              @click="deleteComment(scope.row.comment_uuid)"
              type="text"
              class="deleterow"
              icon="el-icon-delete"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <Page :total="total" :pageSize="5" @number="pagesChanged"></Page> -->
    <div class="create-note">
      <a @click="show = true">+ Add Note</a>
    </div>
    <el-dialog title="Create Note" :visible.sync="show">
      <div class="sh_form">
        <el-form>
          <el-form-item label="Create Note">
            <el-input v-model="note" type="textarea" resize="none" :autosize="{ minRows: 5 }"></el-input>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button class="light-blue-button" @click="clear">Cancel</el-button>
          <el-button class="primary-button" @click="createNote">Save</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { apiCreateSecurityDepositComment, apiDeleteSecurity, } from "../../../API/api";
import Page from "../../common/Paging";
export default {
  props: ["noteData","lease_id"],
  data() {
    return {
      note: "",
      show: false,
      total: 1,
      current: 1,
    };
  },
  components: { Page },
  methods: {
    deleteNote(id) {
      this.$emit("deleteId", id);
    },
    clear() {
      this.note = "";
      this.show = false;
    },
    createNote() {
      apiCreateSecurityDepositComment(this.lease_id, {
        content: this.note,
      })
        .then((res) => {
          this.$message.success("Created successfully");
          this.note = "";
          this.$parent.getSecurityComment();
          this.show = false;
        })
        .catch((err) => {
          this.$message.error(
            "Notes can only be created by the one who created it."
          );
        });
    },
    deleteComment(id) {
      apiDeleteSecurity(this.lease_id, id).then((res) => {
          this.$message.success("Delete succeeded");
          this.$parent.getSecurityComment();
        }).catch((err) => {
          this.$message.error(
            "Notes can only be deleted by the one who created it."
          );
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/table.scss";
@import "../../../css/button.scss";
.note_box {
  background-color: #fff;
  .note-title {
    margin-top: 10px;
    text-align: left;
    font-family: "Roboto-Bold", sans-serif;
    font-size: 18px;
    color: #36425d;
  }
  /deep/ .el-table {
    td {
      padding: 10px 0;
    }
    .deleterow {
      font-size: 22px;
      color: #dd6161;
    }
    .note-text {
      margin-bottom: 0;
      white-space: pre-wrap;
    }
    .fr {
      text-align: right;
      font-size: 10px;
      color: #678993;
      margin: 0;
      font-family: "Roboto-Bold", sans-serif;
    }
  }
  // .create_note {
  /deep/ .el-dialog {
    width: 500px;
    .el-dialog__header {
      text-align: left;
      font-family: "Roboto-Bold", sans-serif;
      .el-dialog__title {
        font-size: 22px;
        color: #38425b;
      }
      position: relative;
      &::after {
        content: "";
        position: absolute;
        border: 1px solid #e1e9ef;
        width: calc(100% - 3px);
        left: 0;
        bottom: 0px;
      }
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #678993;
      font-size: 25px;
      font-weight: 600;
    }
    .el-dialog__body {
      padding-top: 10px;
    }
    @media (max-width:992px) {
      width: 9 0%;
    }
  }
  .sh_form {
    /deep/ .el-form-item__label {
      font-family: "Roboto-Regular", sans-serif;
    }
    /deep/ .el-textarea__inner {
      font-family: "Roboto-Regular", sans-serif;
    }
  }
  .create-note {
    text-align: left;
    margin-top: 10px;
    a {
      font-family: "Roboto-Bold", sans-serif;
      text-decoration: underline;
      color: #678993;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .btn {
    text-align: right;
    .el-button {
      width: 100px;
    }
  }
}
</style>