<template>
  <div class="security_deposit">
    <div style="padding: 0 20px 0 20px">
      <div class="top_bax">
        <p class="title">Security Deposit</p>
      </div>
      <Payment-Status :data="data.security_deposit"></Payment-Status>
      <br>
      <!-- 保证金备注 -->
      <Note :noteData="commonList" :lease_id="$route.params.id" :total="total" @pagesChanged="pagesChanged">
        <template slot="note-title">Security Deposit Notes</template>
      </Note>
      <!--上传附件  -->
      <Attaced-Document :data="fileList"></Attaced-Document>
      <!-- 展示附件 -->
      <div class="show_flie"></div>
      <!-- 退还保证金 -->
      <!-- <Security-Deposit-Return :data="data"></Security-Deposit-Return> -->
      <!-- cancal lease弹出框 -->
      <Cancel-Dialog ref="showCancelDialog"></Cancel-Dialog>
    </div>
    <div class="payment-bottom">
      <el-button class="plight-blue-button" @click="back">
        <p class="row">
          <i class="el-icon-back"></i><span>Previous</span>
        </p>
        </el-button>
    </div>
  </div>
</template>
<script>
import { apiGetLease, apiGetSecurityDepositComment } from "../../API/api";
import Note from "./SecurityDeposit/SecurityDepositNote";
import AttacedDocument from "./SecurityDeposit/SecurityAttacedDocument";
import LeaseTopInfor from "./Component/LeaseTopInfor";
import CancelDialog from "./Component/LeaseCancelDialog";
import PaymentStatus from "./SecurityDeposit/SecurityPaymentStatus";
import SecurityDepositReturn from "./SecurityDeposit/SecurityPaymentReturn";
export default {
  components: { AttacedDocument, Note, LeaseTopInfor, CancelDialog, PaymentStatus, SecurityDepositReturn,},
  data() {
    return {
      selectType: false,
      desc: "",
      tenant_pay: "",
      owner_pay: "",
      other_name: "",
      other_pay: "",
      deposit: "",
      fileList: [],
      data: { security_deposit: {} },
      commonList: [],
      total: 1,
      current: 1,
    };
  },
  computed: {
    offset() {
      return (this.current - 1) * 5;
    },
    query() {
      return {
        offset: this.offset,
        limit: 5,
      };
    },
  },
  methods: {
    back() {
      this.$router.push("/lease/" + this.$route.params.id + "/agreement");
    },
    // 获取保证金备注
    getSecurityComment() {
      apiGetSecurityDepositComment(this.$route.params.id, this.query).then(
        (res) => {
          this.commonList = res.results;
          this.total = res.count;
        }
      );
    },
    pagesChanged(number) {
      this.current = number;
      this.getSecurityComment();
    },
    getLease() {
      apiGetLease(this.$route.params.id).then((res) => {
        this.$store.commit("setLease", JSON.parse(JSON.stringify(res)));
        this.data = res;
        this.commonList = res.security_deposit.comments;
        this.fileList = res.security_deposit.attach_files;
        if (this.data.sign_info || this.data.sign_file_info) {
          this.$store.commit("setLeaseAgeent", true);
        }
        this.$store.commit("setLeaseDetail", true);
        if (this.data.security_deposit.status === "Paid") {
          this.$store.commit("setLeaseSecurityDeposit", true);
        }
      });
    },
  },
  created() {
    if (this.$route.params.id) {
      if (this.$store.state.lease.lease_id === this.$route.params.id - 0) {
        this.data = JSON.parse(JSON.stringify(this.$store.state.lease));
        this.current = 1;
        this.commonList = this.data.security_deposit.comments;
        this.fileList = this.data.security_deposit.attach_files;
        if (this.data.security_deposit.status === "Paid") {
          this.$store.commit("setLeaseSecurityDeposit", true);
        }
      } else {
        this.getLease();
      }
    }
  },
};
</script>
<style lang="scss" scoped>
/* 头部信息 */
.top_bax {
  background-color: #fff;
  position: relative;
  .title {
    font-family: "Roboto-Bold", sans-serif;
    margin: 20px 0 0 0;
    font-size: 20px;
    text-align: left;
    color: #36425d;
  }
  &::after {
    content: "";
    border: 1px dashed #e1e9ef;
    position: absolute;
    width: 100%;
    bottom: -20px;
    left: 0;
  }
}
.security_deposit {
  padding-bottom: 100px;
}
.payment-bottom {
  position: relative;
  text-align: left;
  margin-top: 110px;
  padding: 20px 20px 0 20px;
  .el-button {
    padding: 0 20px;
    .row {
      justify-content: space-between;
      width: 100px;
      align-items: center;
      i {
        font-size: 18px;
      }
    }
  }
  &::after {
    content: "";
    border: 1px solid #e1e9ef;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
</style>
