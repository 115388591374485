<template>
  <div class="security-payment-return">
    <h3 class="payment-title">Security Deposit Return</h3>
    <div class="table">
      <table
        width="100%"
        style="white-space: nowrap; border-collapse: collapse"
      >
        <tr class="table_top">
          <th>Departure</th>
          <th>Return Deadline</th>
          <th>Days Until Deadline</th>
          <th>Total Deposit</th>
        </tr>

        <tr>
          <td>{{ data.departure_date | dateFormat }}</td>
          <td>
            {{
              data.security_deposit.return_deadline | dateFormat
            }}
          </td>
          <td>
            {{ data.security_deposit.return_deadline | distanceNow }} days
          </td>
          <td>$ {{ data.security_deposit_amount| currencyPrice }}</td>
        </tr>
        <tr class="return_amount">
          <td></td>
          <td></td>
          <td colspan>Return to Tenant</td>
          <td>
            <el-input
              resize="none"
              v-model="infor.tenant"
              :disabled=" JSON.stringify(data.security_deposit.return_info) === '{}' && ($store.state.userInfo.is_admin === true || data.user === $store.state.userInfo.user_id) ? false: true"
              type="number"
            >
              <strong slot="prefix">$</strong></el-input
            >
          </td>
        </tr>
        <tr class="return_amount">
          <td></td>
          <td></td>
          <td>Pay to Owner</td>
          <td>
            <el-input
              resize="none"
              :disabled="
                JSON.stringify(data.security_deposit.return_info) === '{}' &&
                ($store.state.userInfo.is_admin === true ||
                  data.user === $store.state.userInfo.user_id)
                  ? false
                  : true
              "
              v-model="infor.homeowner"
              type="number"
              ><strong slot="prefix">$</strong></el-input
            >
          </td>
        </tr>
        <tr class="return_amount">
          <td></td>
          <td></td>
          <td>
            Pay to
            <el-input
              :disabled="
                JSON.stringify(data.security_deposit.return_info) === '{}' &&
                ($store.state.userInfo.is_admin === true ||
                  data.user === $store.state.userInfo.user_id)
                  ? false
                  : true
              "
              v-model="infor.other_name"
            ></el-input>
          </td>
          <td>
            <el-input
              :disabled="
                JSON.stringify(data.security_deposit.return_info) === '{}' &&
                ($store.state.userInfo.is_admin === true ||
                  data.user === $store.state.userInfo.user_id)
                  ? false
                  : true
              "
              v-model="infor.other_pay"
              resize="none"
              type="number"
              ><strong slot="prefix">$</strong></el-input
            >
          </td>
        </tr>
        <tr class="return_amount">
          <td></td>
          <td></td>
          <td>Balance of Deposit</td>
          <td>$ {{ balance_deposit | currencyPrice }}</td>
        </tr>
      </table>
      <p class="deposit_btn">
        <el-button
        :loading="loading"
          :disabled="
            JSON.stringify(data.security_deposit.return_info) === '{}' &&
            ($store.state.userInfo.is_admin === true ||
              data.user === $store.state.userInfo.user_id)
              ? false
              : true
          "
          class="block-button"
          @click="backSecurityDeposit"
        >
          Submit Return Authorization
        </el-button>
      </p>
    </div>
  </div>
</template>
<script>
import { apiReturnSecurity } from "../../../API/api";
export default {
  props: ["data"],
  data() {
    return {
      infor: {
        tenant: "",
        homeowner: "",
        other_name: "",
        other_pay: "",
      },
      deposit: "",
      loading: false,
    };
  },
  computed: {
    balance_deposit() {
      if (this.data) {
        let balance =
          this.data.security_deposit.amount -
          this.infor.tenant -
          this.infor.homeowner -
          this.infor.other_pay;
        if (balance <= -1) {
          this.$message.warning(
            "Cannot exceed the total amount of the deposit."
          );
        }
        this.deposit = balance;
        return balance;
      } else {
        return 0;
      }
    },
  },
  watch: {
    "data.security_deposit.return_info"(newV) {
      if (newV) {
        this.infor.tenant = newV.tenant;
        this.infor.homeowner = newV.homeowner;
        this.infor.other_name = newV.other_name;
        this.infor.other_pay = newV.other_pay;
      }
    },
  },
  methods: {
    // 退还保证金
    backSecurityDeposit() {
      if (this.deposit === 0) {
        this.loading = true;
        apiReturnSecurity(this.$route.params.id, {
          return_info: this.infor,
        }).then((res) => {
          this.$message.success("Deposit returned successfully");
          if (res.status === "Paid") {
            this.$router.go(0)
            this.$store.commit("setLeaseSecurityDeposit", true);
          }
        });
      } else if (this.deposit <= -1) {
        this.$message.warning("Cannot exceed the total amount of the deposit.");
      } else {
        this.$alert("The deposit should be returned in full.", {
          confirmButtonText: "OK",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./SecurityTable.scss";
@import "../../../css/button.scss";
.security-payment-return {
  .deposit_btn {
    text-align: right;
  }
  /deep/ input::-webkit-outer-spin-button,
  /deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  /deep/ input[type="number"] {
    -moz-appearance: textfield;
  }
  /deep/ .el-input {
    width: 150px;
    height: 40px;

    .el-input__inner {
      padding-left: 50px;
      resize: none;
    }
    .el-input__prefix {
      left: 1px;
      width: 35px;
      top: 1.5px;
      height: calc(100% - 3px);
      background-color: #f4f9fc;
      strong {
        line-height: 40px;
      }
    }
  }
}
</style>