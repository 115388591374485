<template>
  <div class="payment-status-box">
    <h3 class="payment-title">Payment Status</h3>
    <div class="table">
      <table width="100%" style="white-space: nowrap; border-collapse: collapse;min-width: 900px" >
        <tr class="table_top">
          <th>PAYMENT #</th>
          <th>DUE DATE</th>
          <th>Total Due</th>
          <th>AMOUNT RECEIVED</th>
          <th width='200px'>BALANCE DUE</th>
          <th min-width='100px'>Status</th>
        </tr>
        <tr>
          <td>{{ data.lease }}</td>
          <td>{{ data.return_deadline | dateFormat }}</td>
          <td>$&nbsp;{{ data.amount | currencyPrice }}</td>
          <td>$&nbsp;{{ data.amount_received | currencyPrice }}</td>
          <td>
            $&nbsp;{{ (data.amount - data.amount_received) | currencyPrice }}
          </td>
          <td>
            <div class="row">
              <span class="lb_color" v-if="data.status == 'Paid'" style="background-color: #73edb0"></span>
              <span class="lb_color" v-else-if="data.status == 'Past Due'" style="background-color: #db272d"></span>
              <span class="lb_color" v-else style="background-color: #fbca25"></span>
              <span v-if="data.status == 'Paid' && data.status == 'Past Due'">{{ data.status }}</span>
              <span v-else>{{ data.status }}</span>
              <!-- <span v-else
              >{{ data.status }} in
              {{ data.return_deadline | distanceNow }}
              days</span
            > -->
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
};
</script>
<style lang="scss" scoped>
@import "./SecurityTable.scss";
.payment-status-box {
  margin-top: 40px;
}
</style>